import React, { createContext, useState, useEffect } from 'react';

import Client from 'shopify-buy';

const client = Client.buildClient({
  domain: process.env.GATSBY_SHOPIFY_STORE_URL,
  storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
});

const defaultValues = {
  isOverlayActive: false,
  setOverlayActive: () => {},

  isCartOpen: false,
  toggleCartOpen: () => {},

  client,
  checkout: {
    id: 0,
    lineItems: [],
    subtotalPrice: 0,
    webUrl: '',
  },
  addProductsToCheckout: () => {},
  updateProductInCheckout: () => {},
  removeProductFromCheckout: () => {},
};

export const StoreContext = createContext(defaultValues);

export const StoreProvider = ({ children }) => {
  const [isOverlayActive, setOverlayActive] = useState(
    defaultValues.isOverlayActive
  );
  const [isCartOpen, setCartOpen] = useState(defaultValues.isCartOpen);
  const [checkout, setCheckout] = useState(defaultValues.checkout);

  const isBrowser = typeof window !== 'undefined';
  const localStorageCheckoutIdKey = `${process.env.GATSBY_SHOPIFY_SHOP_NAME}:checkout-id`;

  useEffect(() => {
    initialiseCheckout();
  }, []);

  const toggleCartOpen = () => {
    setCartOpen(!isCartOpen);
  };

  const initialiseCheckout = async () => {
    const checkoutId = isBrowser
      ? localStorage.getItem(localStorageCheckoutIdKey)
      : null;

    let newCheckout;

    if (checkoutId) {
      try {
        newCheckout = await client.checkout.fetch(checkoutId);

        if (
          newCheckout === null ||
          newCheckout.completedAt ||
          newCheckout.lineItems.find(({ variant }) => variant === null)
        ) {
          newCheckout = await getNewCheckout();
        }
      } catch (e) {
        console.error(e);
        newCheckout = await getNewCheckout();
      }
    } else {
      newCheckout = await getNewCheckout();
    }

    setCheckout(newCheckout);
  };

  const getNewCheckout = async () => {
    try {
      const newCheckout = await client.checkout.create();

      if (isBrowser) {
        localStorage.setItem(localStorageCheckoutIdKey, newCheckout.id);
      }

      return newCheckout;
    } catch (e) {
      console.error(e);
    }
  };

  const addProductsToCheckout = async lineItems => {
    try {
      const updatedCheckout = await client.checkout.addLineItems(
        checkout.id,
        lineItems
      );

      setCheckout(updatedCheckout);

      toggleCartOpen();
    } catch (e) {
      console.error(e);
    }
  };

  const updateProductInCheckout = async (lineItemId, quantity) => {
    try {
      const updatedCheckout = await client.checkout.updateLineItems(
        checkout.id,
        [
          {
            id: lineItemId,
            quantity: quantity,
          },
        ]
      );

      setCheckout(updatedCheckout);
    } catch (e) {
      console.error(e);
    }
  };

  const removeProductFromCheckout = async lineItemId => {
    try {
      const updatedCheckout = await client.checkout.removeLineItems(
        checkout.id,
        [lineItemId]
      );

      setCheckout(updatedCheckout);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,

        isOverlayActive,
        setOverlayActive,

        isCartOpen,
        toggleCartOpen,

        client,
        checkout,
        addProductsToCheckout,
        updateProductInCheckout,
        removeProductFromCheckout,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
